* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

html {
  background-color: rgb(57, 62, 65);
}

#root {
  background-color: rgb(57, 62, 65);
}

.container {
  min-height:100%;
  position:relative;
}

header {
  position: relative;
}

.kc-logo {
  width: 300px;
  height: auto;
  position: absolute;
  top: -8px;
  left: -52px;
}

.nav {
  justify-content: end;
  width: auto;
  padding-top: 15px;
  padding-right: 17px;
}

.nav-tabs {
  border-color: rgb(57, 62, 65);
}

.nav-link, .nav-item {
  color: white;
  border: none !important;
}

.nav-link:hover {
  color: #82e1cf;
  border-color: rgb(57, 62, 65);
  border: none;
}

/* .nav-link:visited {
  color: inherit;
} */

.active-tab {
  color: #44BBA4 !important;
}

.button-wrapper{
  text-align: end;
  
  }
  
  .menu-button {
    color: white;
    background-color: transparent;
    border: 0px;
    font-size: 70px;
    padding-right: 20px;
  }
  
  .menu-button:hover {
    color: #44BBA4;
  }
  
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    border: none;
    
  }
  
  .sidenav a {
    display: block;
    transition: 0.3s;
    display:block;
    text-align: center;
  }
  
  .sidenav a:hover {
    color: #44BBA4;
  }
  
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    display: contents;
  }
  
  .closebtnWrapper {
    text-align: center;
  }
  
  .closebtn {
    color: white;
  }

main {
  margin-top: 150px;
  min-height: calc(100vh - 340px);
  margin-bottom: 50px;
}

#contact-me {
  margin-top: 0;
  margin-bottom: 193px;
}

p {
  text-align: center;
  color: rgb(233, 79, 55);
  font-size: 25px;
}

main .container .row .col-sm-6 p {
  margin-top: 10px;
}

.home-caption {
  color: #44BBA4 ;
}

h1 {
  
  margin-bottom: 32px 0;
  color: rgb(246, 247, 235);
  font-size: 75px;
  filter: drop-shadow(1px 10px 20px #a8a8a8);
  text-align: center;
  
}

h2 {
  text-align: center;
  color: rgb(233, 79, 55);
  font-size: 40px;
}

.pro-title {
  padding-top: 70px;
  text-align: center;
  color: white;
  font-size: 40px;
  filter: drop-shadow(1px 10px 20px #a8a8a8);
}

h3 {
  margin: auto;
  color: rgb(66, 171, 231);
  font-size: 20px;
}

.btn-wrapper {
  margin-top: 60px;
  text-align: center;
  
}

.btn-view-work {
  background-color: rgb(57, 62, 65);
  color: rgb(246, 247, 235);
  border: 2px  solid white;
}

.btn-view-work:hover {
  background-color: #44BBA4;
  border: 2px  solid white;
  color: rgb(246, 247, 235);
}

i {
  font-size: 50px;
  color: white;
}

i:hover {
  font-size: 50px;
  color: #44BBA4;
}

span {
  margin: 16px;
}

#portfolio {
  margin-top: 0px;
  min-height: calc(100vh - 345px);
}

#about {
  margin-top: 0px;
  margin-bottom: 0;
  min-height: calc(100vh - 375px);
}

.about-img {
  padding-top: 90px;
  max-width:100%;
  max-height:100%;
}

.col-sm-6 {
  height: fit-content;
  margin-bottom: 40px;
  margin-top: 5px;
}

.port-img {
  max-width:100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  opacity: 1;
  display: block;
}

.row {
  margin-top: 30px;
  
}

main .container .row .col-sm-6 {
  padding-right: calc(var(--bs-gutter-x) * .94);
  padding-left: calc(var(--bs-gutter-x) * .94);
}

.img-container {
  position: relative;
  height: fit-content;
  overflow: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.img-container:hover .port-img {
  opacity: 0.2;
}

.img-container:hover .middle {
  opacity: 1;
}

.caption {
  color: white;
}

.pro-li-title{
  font-size: 30px;
}

ul{
  padding-right: 0px;
  padding-left: 38%;
  align-items: right;
}

li {
  color: white;
  text-align: left;
  font-size: 26px;
}

.contact-links{
  text-align: center;
}

a{
  color: rgb(233, 79, 55);
}

a:hover {
  color: #44BBA4;
}

form {
  padding-right: 25%;
  padding-left: 25%;
  align-items: center;
}

label {
  color: rgb(233, 79, 55);
  font-size: 30px;
}

input {
  margin-bottom: 20px;
}

textarea {
  margin-bottom: 20px;
}

.btn:hover {
  background-color: #44BBA4;
  color: white;
}

footer {
  height: 68px;
  width: 100%;
  text-align: center;
}


@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}


@media (max-width: 674px) {
  main {
    min-height: calc(100vh - 575px);
  }
}


@media (max-width: 991px) {
  
.col-sm-6 {
  flex: 0 0 auto;
  width: 100%;
}

form {
  padding-right: 10%;
  padding-left: 10%;
}

main {
  min-height: calc(100vh - 345px);
}

}

@media (max-width: 772px) {
  main {
    min-height: calc(100vh - 378px);
  }
}